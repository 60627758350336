import { render, staticRenderFns } from "./ContractSavingsProfilingPoliticallyExposedPerson.vue?vue&type=template&id=84316136&"
import script from "./ContractSavingsProfilingPoliticallyExposedPerson.vue?vue&type=script&lang=ts&"
export * from "./ContractSavingsProfilingPoliticallyExposedPerson.vue?vue&type=script&lang=ts&"
import style0 from "./ContractSavingsProfilingPoliticallyExposedPerson.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VCard,VCol,VDialog,VRow,VSheet})
