

































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

@Component({ name: 'ContractSavingsProfilingPoliticallyExposedPerson' })
export default class ContractSavingsProfilingPoliticallyExposedPerson extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  i18n_namespace = 'components.contract-savings.profiling.politically-exposed-person';

  small_screen: null | boolean = null;

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }
}

